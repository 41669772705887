import React, { useState } from 'react'
import './home.css'
import Navbar from '../navbar/navbar'
import axios from 'axios'
import { url } from '../../config/backed_url'
import { useEffect } from 'react'
import USER_ICO from '../../images/ph_user-bold.png'
import STAR_ICO from '../../images/ph_star.png'
import TIME_ICO from '../../images/carbon_time.png'
import DOC1 from '../../images/doc1.svg'
import DOC2 from '../../images/doc2.svg'
import PERSON_SMALL from '../../images/person-small.png'
import PERSON_SMALL_2 from '../../images/user2.png'
import PERSON_SMALL_3 from '../../images/user3.png'
import nextICO from '../../images/up-arrow.svg'



import Footer from '../footer/footer'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Doc1SVG } from '../../images/doc1.svg';
import { ReactComponent as Doc2SVG } from '../../images/doc2.svg';




function Home({setLogin, login}) {

  

  const navigate = useNavigate();

  const [indecations, setIndecations] = useState([])
  const [un_filtered_indecations, setUNIndecations] = useState([])

  const handleRedirect = (indication) => {
    navigate('/dashboard', {
      state: { selectedIndecation: indication, indecations: indecations, un_filtered_indecations: un_filtered_indecations },
    });
  };


  const handleLogin = (indication) => {
    if (indication) {
      localStorage.setItem('pendingIndication', JSON.stringify(indication));
    }
    navigate('/login');
  };


  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional for smooth scrolling
    });
  }, []); 

  useEffect(() => {

    const cachedData = localStorage.getItem('indications');
    if (cachedData) {
      setIndecations(JSON.parse(cachedData));

      axios.get(`${url}/api/v1/general/`)
      .then((res) => {
        setIndecations(res.data);
        localStorage.setItem('indications', JSON.stringify(res.data)); 
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      axios.get(`${url}/api/v1/general/`)
      .then((res) => {
        setIndecations(res.data);
        localStorage.setItem('indications', JSON.stringify(res.data)); 
      })
      .catch((err) => {
        console.log(err);
      });
    }


    const pendingIndication = localStorage.getItem('pendingIndication');

    if (login && pendingIndication) {
      const indication_ = JSON.parse(pendingIndication);
      console.log(indication_);
      handleRedirect(indication_);
      localStorage.removeItem('pendingIndication'); // Clean up
    }
  
  }, []);


const customerFeedback = [
  {
    image: PERSON_SMALL,
    text: "“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...",
    name: "Alex Bern"
  },
  {
    image: PERSON_SMALL_2,
    text: "“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...",
    name: "Lucia Smith"
  },
  {
    image: PERSON_SMALL_3,
    text: "“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...",
    name: "Sarah Bernie"
  },
  {
    image: PERSON_SMALL,
    text: "“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...",
    name: "Alex Bern"
  },
  {
    image: PERSON_SMALL_2,
    text: "“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...",
    name: "Lucia Smith"
  },
  {
    image: PERSON_SMALL_3,
    text: "“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...",
    name: "Sarah Bernie"
  }
];


const scrollLeft = () => {
  const slider = document.getElementById('slider');
  slider.scrollLeft -= 500; // Adjust scroll amount based on your slide width
};

const scrollRight = () => {
  const slider = document.getElementById('slider');
  slider.scrollLeft += 500; // Adjust scroll amount based on your slide width
};


  function scrollToIndications() {
    const element = document.getElementById("indications");
    element.scrollIntoView({ behavior: "smooth" });
  }
  
  
  return (
    <div className='home'>
        <Navbar setLogin={setLogin} login={login} />

       {/* IMG SIDE CONTENT SESSION */}

       <div className="side-content-container">
          <div id='s-b' className="to_the_sym_btn_">
            <button onClick={scrollToIndications} className='to_the_sym_btn'>direkt zu den Symptomen</button>
          </div>
          <div className="space"></div>
          <div className="side-content">
            <h3 className='content-text-heading'>Sie sind individuell, Ihre Symptome auch</h3>
            {/* <img src={DOC2} alt=""srcset="" /> */}
            <Doc2SVG className='img_in' />
            <div className="content-text">
              <h3 className='hide'>Sie sind individuell, Ihre Symptome auch</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur. Phasellus pharetra facilisi sit neque. Sed proin netus mauris ut tempus sit. Pharetra tincidunt magna fermentum fringilla. Morbi ipsum phasellus nunc pretium feugiat lectus. Odio faucibus mauris ultricies purus. Faucibus facilisis mi ultrices amet a pulvinar. Accumsan elit vitae co <br /> <br /> <br /> nsectetur blandit enim amet integer. Augue lacus pretium gravida metus viverra egestas eu mauris ultrices. Nascetur viverra vel facilisis sit id netus. Rhoncus mauris in viverra tempor facilisis volutpat eu.
                Risus pellentesque sagittis elementum eu. Turpis odio cras facilisis sed. Ornare mauris ipsum ornare sed et faucibus et tell
              </p>
            </div>
          </div>
          {/* <div className="space"></div> */}
          <div className="side-content s-c-b">
            <div className="content-text doc2-text">
              <h3 className='hide'>Lorem ipsum dolor sit amet</h3>
              <p>
              Lorem ipsum dolor sit amet consectetur. Phasellus pharetra facilisi sit neque. Sed proin netus mauris ut tempus sit. Pharetra tincidunt magna fermentum fringilla. Morbi ipsum phasellus nunc pretium feugiat lectus. Odio faucibus mauris ultricies purus. Faucibus facilisis mi ultrices amet a pulvinar. Accumsan elit vitae co <br /> <br /> <br /> nsectetur blandit enim amet integer. Augue lacus pretium gravida metus viverra egestas eu mauris ultrices. Nascetur viverra vel facilisis sit id netus. Rhoncus mauris in viverra tempor facilisis volutpat eu.
              Risus pellentesque sagittis elementum eu. Turpis odio cras facilisis sed. Ornare mauris ipsum ornare sed et faucibus et tell
              </p>
            </div>
            <h3 className='content-text-heading'>Lorem ipsum dolor sit amet</h3>
            {/* <img className='doc2' src={DOC1} alt="" srcset="" /> */}
            <Doc1SVG className='doc2 img_in' />
            <p className='content-text-para'>
              Lorem ipsum dolor sit amet consectetur. Phasellus pharetra facilisi sit neque. Sed proin netus mauris ut tempus sit. Pharetra tincidunt magna fermentum fringilla. Morbi ipsum phasellus nunc pretium feugiat lectus. Odio faucibus mauris ultricies purus. Faucibus facilisis mi ultrices amet a pulvinar. Accumsan elit vitae co <br /> <br /> <br /> nsectetur blandit enim amet integer. Augue lacus pretium gravida metus viverra egestas eu mauris ultrices. Nascetur viverra vel facilisis sit id netus. Rhoncus mauris in viverra tempor facilisis volutpat eu.
              Risus pellentesque sagittis elementum eu. Turpis odio cras facilisis sed. Ornare mauris ipsum ornare sed et faucibus et tell
            </p>
          </div>
        </div>

        <div id="indications" className="the_center_heading_btn">
          <button className='to_the_sym_btn_center'>Symtome</button>
        </div>
        
        <div  className="home-container">
          {/* indications  */}
          <div  className="indications">
            {indecations.map((indecation, index) => (
              <div onClick={ localStorage.getItem('token') ? () => handleRedirect(indecation) : () => handleLogin(indecation)} className='indication' key={index}>
                <img src={indecation.IMAGE} alt="" srcset="" />
                {localStorage.getItem('token') ?
                  <p>{indecation.INDIKATION}</p>
                :
                  <p>{indecation.INDIKATION}</p>
                }
              </div> 
            ))}
              <div className='indication text-center'>
                <p className='extra_ind_text'>mehr symtome comming soon....very soon</p>
              </div> 
          </div>
          
        </div>

        {/* Features */}

        <div className="features">
            <div className="feature-heading">
              <h2>Unsere Features</h2>
            </div>
            <div className="features-container">
              <div className="feature">
                  <div className="feature-icon">
                    <img src={USER_ICO} alt="" srcset="" />
                  </div>
                  <div className="feature-content">
                    <h3>Personalisierte Empfehlungen</h3>
                    <p>Erhalten Sie spezifische Arzneimittelvorschläge für Ihre Krankheit.</p>
                  </div>
              </div>
              <div className="feature">
                  <div className="feature-icon">
                    <img src={STAR_ICO} alt="" srcset="" />
                  </div>
                  <div className="feature-content">
                    <h3>Benutzerfreundlich</h3>
                    <p>Wählen Sie Ihre Krankheit aus und erhalten Sie sofort eine Liste geeigneter Medikamente.</p>
                  </div>
              </div>
              <div className="feature">
                  <div className="feature-icon">
                    <img src={TIME_ICO} alt="" srcset="" />
                  </div>
                  <div className="feature-content">
                    <h3>Zeitersparnis</h3>
                    <p>Sparen Sie sich lange Recherchen, das System findet sofort die richtigen Medikamente für Sie.</p>
                  </div>
              </div>
            </div>
        </div>

        {/* END FEATUES */}

 

        {/* SLIDER */}


    <div className="slider-cont">
      <h2 className="slider-heading">Das sagen unsere Kunden</h2>
      <div className="silder-holder">
        <div className="next-btn-holder" onClick={() => scrollLeft()}>
          <img src={nextICO} alt="Previous" />
        </div>

        <div className="slider" id="slider">
          {/* Dynamically render slides from customerFeedback */}
          {customerFeedback.map((feedback, index) => (
            <div className="slide" key={index}>
              <img src={feedback.image} alt={feedback.name} />
              <div className="slide-content">
                <p>{feedback.text}</p>
                <span>{feedback.name}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="next-btn-holder-2" onClick={() => scrollRight()}>
          <img src={nextICO} alt="Next" />
        </div>
      </div>
    </div>

        {/* END SLIDER */}

        <Footer />
    </div>
    
  )
}

export default Home


// setUNIndecations(res.data)
// const uniqueIndecations = res.data.filter((indecation, index, self) =>
//   index === self.findIndex((t) => (
//     t.INDIKATION === indecation.INDIKATION && t.IMAGE !== null && t.about !== null && t.tips !== null
//   )) 
// );