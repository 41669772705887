import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../navbar/navbar';
import { useLocation } from 'react-router-dom';
import './dashboard.css'
import tooltip from '../../images/tooltip.png'
import hGender from '../../images/h-gender.svg'
import hStar from '../../images/h-star.svg'
import hSearch from '../../images/h-search.svg'
import hGenderWHITE from '../../images/h-gender-white.svg'
import hStarWHITE from '../../images/h-star-white.svg'
import hSearchWHITE from '../../images/h-search-white.svg'
import smallFilter from '../../images/mdi_filter.png'
import hheart from '../../images/h-heart.svg'
import hheartWhite from '../../images/h-heart-white.svg'
import shoping_cart from '../../images/shoping.png'
import Male from '../../images/male.png'
import Female from '../../images/female.png'
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { url } from '../../config/backed_url.js';
import Footer from '../footer/footer.jsx';


function Dashboard({ setLogin }) {

    const location = useLocation();
    const navigate = useNavigate();
    const [age, setAge] = useState(null)
    
    const [questions, setQuestions] = useState([])
    const [is_both, setBoth] = useState(true)

    
    const [selectedIndecation, setSelectedIndecation] = useState(null);
    const [unFilIndecation, setUnFilIndecation] = useState(null);

    const [indecations, setIndecations] = useState(null);
    const [products, setProducts] = useState(null);
    const [pregnant, setPregnant] = useState(null);
    const [breastfeeding, setBreastfeeding] = useState(null);
    const [ERR_R, setERR_R] = useState(false);


    useEffect(() => {
        axios.get(`${url}/api/v1/generals/`)
        .then((res) => {
          setUnFilIndecation(res.data)
        }).catch((err) => {
          console.log(err);
        })

        if (location.state && location.state.selectedIndecation) {
            setSelectedIndecation(location.state.selectedIndecation || null);
            setIndecations(location.state.indecations?.length !== 0 ? location.state.indecations : JSON.parse(localStorage.getItem('indications')))
        }

        console.log(location.state.indecations);
        console.log(JSON.parse(localStorage.getItem('indications')));
        
        

        const paragraphs = location.state.selectedIndecation.questions.split('\n');
        setQuestions(paragraphs)
        setScreen(1)
        setAge(null)
        setSubmit(false)
        setNoNext(true)
        setC(0)
        setAgeErr(false)
        setERR_R(false)
        
        
    }, [location.state]);


    const handleRedirect = (indication) => {
        navigate('/dashboard', {
          state: { selectedIndecation: indication, indecations: indecations },
        });
    };
 

    // FORM DATA
    const [screen, setScreen] = useState(1);
    const [submit, setSubmit] = useState(false)
    const [no_next, setNoNext] = useState(true)
    const [c, setC] = useState(0)
    const [age_err, setAgeErr] = useState(false)
    const [openFilter, setOpenFilter] = useState(null); // Track the index or id of the open filter
    
    const toggleFilter = (indicationId) => {
        setOpenFilter(openFilter === indicationId ? null : indicationId); // Toggle open/close
    };
    

    const [no_next_question, setNoNextQuestion] = useState(true)

    const NoNextHandler = (e) => {
        const { checked } = e.target;

        if (screen === 2) {
            setC(prevC => {
                const newC = checked ? prevC + 1 : prevC - 1;
    
                // If 2 or more checkboxes are selected, allow proceeding to the next step
                if (newC >= 2) {
                    setNoNext(false); 
                    setERR_R(false)
                    // Enable the "Next" button
                } else {
                    setNoNext(true);   // Disable the "Next" button
                }
    
                return newC; // Update state with the new counter value
            });
        }
        
        

        if(checked){
            setNoNext(false)
            setERR_R(false)
        } else {
            setNoNext(true)
        }


    }

    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const no_next_question_handler = (e) => {
        setNoNext(true)
        const { checked } = e.target;
        let updatedQuestions = [...selectedQuestions];
    
        if (checked) {
            updatedQuestions.push(1);  // Add a value to the array
            setSelectedQuestions(updatedQuestions);
    
            if (updatedQuestions.length !== 0) {
                setNoNextQuestion(false);  // Disable proceed button
            }
        } else {
            updatedQuestions.pop();  // Remove the last value from the array
            setSelectedQuestions(updatedQuestions);
            
            if (updatedQuestions.length === 0) {
                setNoNextQuestion(true);
                // Enable proceed button
            }
        }
    };
    
    

    const [gender, setGender] = useState({
        male: true,
        female: false
    })
    
    const ageHandler = (e) => {
        setAge(e.target.value)
        // setAgeErr(false)
        if (e.target.value !== '') {
            setAgeErr(false)
        }

        if (e.target.value == '') {
            setAge(null)
        }
    }

    const genderhandler = (e) => {
        if (age === null) {
            toast.warning("Fill Age Section Please.")
            return
        }
        const updated_gender = {
            male: !gender.male,
            female: !gender.female
        }
        setGender(updated_gender)
    }

    const screenChanger  = () => {

        if(age == null && screen == 1){
            toast.warning("Fill Age Section Please.")
            setAgeErr(true)
            return
        }

        setScreen(screen+1)
        setNoNext(true)
        setAge(null)
        setERR_R(false)
        
        if (screen == 3) {
            setSubmit(true)
            setNoNext(false)
            return
        }
    }

    const [quantities, setQuantities] = useState({}); // Track quantity for each product

    const handleQuantityChange = (e, id) => {
        const newQuantity = e.target.value;
        setQuantities({
          ...quantities,
          [id]: newQuantity // Update quantity for the specific product by ID
        });
      };

    const [errB, setErrB] = useState(0)

    const CheckerBoth = (e) => {
        const { checked } = e.target;
        if(checked) {
            setErrB(errB+1)
        } else {
            setErrB(errB-1)
        }
    }
    useEffect(() => {
        // When screen 4 loads, scroll to the top of the page
        if (screen === 4) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Optional for smooth scrolling
            });
        } else if (screen === 1){
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Optional for smooth scrolling
            });
        }
    }, [screen]); 

    const WARNFUN = () => {
        toast.warning("field(s) should be filled")
        setERR_R(true)

        if (age == null) {
            setAgeErr(true)
        }
    }

    const getBack = () => {
        if (screen == 1) {
            return
        }
        setScreen(screen - 1)
        setSubmit(false)
    }

    const [selectedSizes, setSelectedSizes] = useState({}); // Track selected sizes for individual products
    const handleSizeClick = (newIndication, indId) => {
        setSelectedSizes((prevState) => ({
            ...prevState,
            [indId]: newIndication, // Update the product by its ID with the new size
        }));
        setOpenFilter(null); // Close the filter after selection
    };


    const addBasket = (product, quan) => {

        const existingBasket = JSON.parse(localStorage.getItem('basket')) || [];
        product.quantity = quan
        const updatedBasket = [...existingBasket, product];
        localStorage.setItem('basket', JSON.stringify(updatedBasket));
        toast.success(`${product.EIGENE_BEZEICHNUNG} Added to the basket`)
    }

    const genderRef = useRef(null);
    const symptomsRef = useRef(null);
    const lifeCircumstancesRef = useRef(null);
    const recommendationRef = useRef(null);

    useEffect(() => {
        if (screen === 1 && genderRef.current) {
          genderRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest'});
        } else if (screen === 2 && symptomsRef.current) {
          symptomsRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' });
        } else if (screen === 3 && lifeCircumstancesRef.current) {
          lifeCircumstancesRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' });
        } else if (screen === 4 && recommendationRef.current) {
          recommendationRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'end' });
        }
      }, [screen]);



    return (
        <div className='dashboard'>
            <Navbar setLogin={setLogin} />
            <div className='dashboard-container'>
                {/* <p>{selectedIndecation ? selectedIndecation.INDIKATION : "No indication selected"}</p> */}
                <div className="sidebar">
                    <div className='side-bar-links'>
                        {indecations ? 
                            indecations.map((indecation, index) => <>
                                <div onClick={() => handleRedirect(indecation)} className={indecation?.INDIKATION == selectedIndecation?.INDIKATION ? "single-link active-tab" : "single-link" } >
                                    <img src={selectedIndecation?.IMAGE ? indecation.IMAGE : ''} alt="" />
                                    <p >{indecation.INDIKATION}</p>
                                </div>
                            </>)
                        : "No indication Found"}
                    </div>
                </div>
                {/* CONTENT AREA */}
                <div className="dashboard-content-area">
                    <div className="headlight">
                        <img src={selectedIndecation?.IMAGE} alt="" />
                        <p>Was hilft bei  {selectedIndecation ? selectedIndecation.INDIKATION : "No indication selected"}?</p>
                    </div>
                    <div className="dashboard-form">

                        <div className="dashboard-form-header-mobile">
                            <div ref={genderRef} className={screen == 1 ? "header-block-mobile active-block" : screen > 1 ? 'header-block-mobile ': 'header-block-mobile  '}>
                                <img src={screen == 1 ? hGenderWHITE : hGender} alt="" srcset="" />
                                <p>Geschlecht & Alter 1/4</p>
                            </div>
                            <div ref={symptomsRef} className={screen == 2 ? "header-block-mobile active-block" :  screen > 2 ? 'header-block-mobile ': 'header-block-mobile  '}>
                                <img src={screen == 2 ? hSearchWHITE : hSearch} alt="" srcset="" />
                                <p>Symptome 2/4</p>
                            </div>
                            <div  ref={lifeCircumstancesRef} className={screen == 3 ? "header-block-mobile active-block" :  screen > 3 ? 'header-block-mobile ': 'header-block-mobile  '}>
                                <img src={screen == 3 ? hheartWhite : hheart} alt="" srcset="" />
                                <p>Lebensumstände 3/4</p>
                            </div>
                            <div  ref={recommendationRef} className={screen == 4 ? "header-block-mobile active-block":  screen == 5 ? 'header-block-mobile ': 'header-block-mobile  '}>
                                <img src={screen == 4 ? hStarWHITE : hStar} alt="" srcset="" />
                                <p>unsere Empfehlung 4/4</p>
                            </div>
                        </div>

                        <div className="dashboard-form-header">
                            <div className={screen == 1 ? "header-block inative-form" : screen > 1 ? 'header-block grey': 'header-block'}>
                                <img className='non-im' src={hGender} alt="" srcset="" />
                                <p>Geschlecht & Alter</p>
                            </div>
                            <div className={screen == 2 ? "header-block inative-form" :  screen > 2 ? 'header-block grey': 'header-block'}>
                                <img src={hSearch} alt="" srcset="" />
                                <p>Symptome</p>
                            </div>
                            <div className={screen == 3 ? "header-block inative-form" :  screen > 3 ? 'header-block grey': 'header-block'}>
                                <img src={hheart} alt="" srcset="" />
                                <p>Lebensumstände</p>
                            </div>
                            <div className={screen == 4 ? "header-block inative-form" :  screen == 5 ? 'header-block grey': 'header-block'}>
                                <img src={hStar} alt="" srcset="" />
                                <p>unsere Empfehlung</p>
                            </div>
                        </div>
                        <div className="the-acutall-form">
                            <div className="form-screens">

                                {screen == 1 ?
                                    <div className="screen">
                                        
                                        <p></p>
                                        <div className="screen-container">
                                            <div className="input-handler-screens">
                                                <label htmlFor="">Alter: </label>
                                                <div className="sider-container targeted">
                                                    <input onChange={(e) => ageHandler(e)} type="number" name="" id="" />
                                                    <p>Jahre</p>
                                                </div>
                                                {age_err ? <p className='error'>You Must Fill Age*</p>: ""}
                                            </div>
                                            <hr />
                                            <div className="input-handler-screens">
                                                <label htmlFor="">Geschlecht: </label>
                                                <div className="sider-container">
                                                    <button onClick={(e) => genderhandler(e)} className={gender.male ? "bg-blue-active" : ''} >
                                                        <img src={Male} alt="" srcset="" />
                                                    </button>
                                                    <button onClick={(e) => genderhandler(e) } className={gender.female ? "bg-blue-active" : ''}  >
                                                        <img src={Female} alt="" srcset="" />
                                                    </button>

                                                    
                                                </div>
                                                
                                            </div>
                                            {gender.female && age >= 12 && age <= 55 ? <>
                                                <div className="input-handler-screens">
                                                    <label htmlFor="">Schwanger</label>
                                                    <div className="sider-container">
                                                        <input className='question-box-input' onChange={CheckerBoth} type="checkbox" name="" value={'qa'} id="" />
                                                    </div>
                                                </div>
                                                <div className="input-handler-screens">
                                                    <label htmlFor="">Stillen</label>
                                                    <div className="sider-container">
                                                        <input className='question-box-input' onChange={CheckerBoth} type="checkbox" name="" value={'qa'} id="" />
                                                    </div>
                                                </div>
                                            </> : ""}
                                            {errB > 1 ? <span className='error'>We Recommed to visit to the doctor but we have some Recommedations for You.</span> : ""}
                                            <hr />
                                            <div className="input-handler-screens">
                                                <label htmlFor="">Hiermit bestätige ich die Infotafel gelesen zu haben. 
                                                    <div class="tooltip-container">
                                                        <img src={tooltip} alt="" srcset="" />
                                                        <div class="tooltip-text" dangerouslySetInnerHTML={{ __html: selectedIndecation?.broad_info }}></div>
                                                    </div>
                                                </label>
                                                <div className="sider-container">
                                                   <input onClick={NoNextHandler} type="checkbox" name="" id="" />
                                                   {ERR_R ? <span className='error'>field(s) should be filled</span> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : ""}


                                {screen == 2 ?
                                    <div className="screen">
                                        <div className="screen-container">
                                            <h3 className='heading-screen-title'>Beschreibt einer oder mehrere der folgenden Punkte einzelne Symptome Ihrer Erkrankung?</h3>

                                            {questions.map((question, index) =>
                                                <div className="input-handler-screens">
                                                    <label htmlFor="" >{question.replace(/\\n|\\r/g, '').trim()}</label>
                                                    <div className="sider-container">
                                                        <input onChange={(e) => no_next_question_handler(e)} className='question-box-input' type="checkbox" name="" value={'qa'} id="" />
                                                    </div>
                                                </div>
                                            )}

                                            <div className="input-handler-screens bold">
                                                <label htmlFor="">Keiner der Punkte trifft zu</label>
                                                <div className="sider-container">
                                                    {!no_next_question ? <input disabled onClick={(e) => NoNextHandler(e)} type="checkbox" name="" id="" /> : <input onClick={(e) => NoNextHandler(e)} type="checkbox" name="" id="" /> }
                                                    {ERR_R ? <span className='error'>field(s) should be filled</span> : ""}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="input-handler-screens">
                                                <label htmlFor="">Hiermit bestätige ich die Infotafel gelesen zu haben.

                                                    <div class="tooltip-container">
                                                        <img src={tooltip} alt="" srcset="" />
                                                        <div class="tooltip-text" dangerouslySetInnerHTML={{ __html: selectedIndecation?.about }}></div>
                                                    </div>
                                                </label>
                                                <div className="sider-container">
                                                    {/* <input onClick={NoNextHandler} type="checkbox" name="" id="" /> */}
                                                    {!no_next_question ? <input disabled onClick={(e) => NoNextHandler(e)} type="checkbox" name="" id="" /> : <input onClick={(e) => NoNextHandler(e)} type="checkbox" name="" id="" /> }
                                                    <br />
                                                    <br />
                                                    {!no_next_question ? <p className='red-msg-err'>Symtom DOCTOR, Kill the Section</p> : '' }
                                                    {ERR_R ? <span className='error'>field(s) should be filled</span> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : ""}

                                {screen == 3 ? 
                                    <div className="screen">
                                        <div className="screen-container">
                                            <h3 className='heading-screen-title'>Trifft eine oder mehrere der folgenden Beschreibungen auf Ihre persönlichen Lebensumstände zu?</h3>
                                            <div className="input-handler-screens">
                                                <label htmlFor="">Stark geschwächtes Immunsystem durch Grunderkrankung oder medikamentöse Therapie</label>
                                                <div className="sider-container">
                                                    <input onChange={(e) => no_next_question_handler(e)} className='question-box-input' type="checkbox" name="" value={'qa'} id="" />
                                                </div>
                                            </div>
                                            <div className="input-handler-screens">
                                                <label htmlFor="">Patient*in unter Chemotherapie</label>
                                                <div className="sider-container">
                                                    <input onChange={(e) => no_next_question_handler(e)} className='question-box-input' type="checkbox" name="" value={'qa'} id="" />
                                                </div>
                                            </div>
                                            <div className="input-handler-screens">
                                                <label htmlFor="">Aktuelle Einnahme von Cortison in Tablettenform</label>
                                                    <div className="sider-container">
                                                <input onChange={(e) => no_next_question_handler(e)} className='question-box-input' type="checkbox" name="" value={'qa'} id="" />
                                            </div>
                                            </div>
                                            <hr />
                                            <div className="input-handler-screens bold">
                                                <label htmlFor="">Keiner der Punkte trifft zu</label>
                                                <div className="sider-container">
                                                    {!no_next_question ? <input disabled onClick={NoNextHandler} type="checkbox" name="" id="" /> : <input onClick={NoNextHandler} type="checkbox" name="" id="" /> }
                                                    {!no_next_question ? <p className='red-msg-err'>Symtom DOCTOR, Kill the Section</p> : '' }
                                                </div>
                                                {ERR_R ? <span className='error'>field(s) should be filled</span> : ""}

                                            </div>
                                        </div>
                                    </div>
                                : ""}

                                {screen == 4 ? <div className='screen'>
                                    <div className="screen-container">
                                        <h1 className='heading-screen-title'>Auf der Grundlage Ihrer Angaben haben wir folgende Empfehlungen für Sie zusammengestellt</h1>
                                        
                                        {unFilIndecation?.filter((inds, i) => inds.INDIKATION == selectedIndecation.INDIKATION && inds.SHOP == 1 && inds.ADJUVANZ == 1 )?.map((ind, index) => {
                                            const product = selectedSizes[ind.id] || ind;
                                            const availableSizes = unFilIndecation.filter(i => 
                                                i.INDIKATION === selectedIndecation.INDIKATION && 
                                                i.GRUPPE === ind.GRUPPE && 
                                                i.GRUPPE !== ''
                                            );


                                           return (<div className='single-product'>
                                            <div className='product-head'>
                                                <p>Arzneimittel</p>
                                                <p>Darreichungsform <img src={smallFilter} alt="" /></p>
                                            </div>   
                                            <div className="product-holder">
                                                <div className="product-holder-nav">
                                                <p  className='f-icon' onClick={() => toggleFilter(ind.id)}>Packungsgröße <img  src={smallFilter} alt="" /></p>

                                                    {openFilter === ind.id && (
                                                        <div className="filter-box">
                                                            <h6>Available Package Sizes</h6>
                                                            {availableSizes.length > 0 ? (
                                                                    availableSizes.map((c, idx) => (
                                                                        <p onClick={() => handleSizeClick(c, ind.id)} key={idx}>{c.PKG} | {c.GRUPPE} | {c.EIGENE_BEZEICHNUNG}</p>
                                                                    ))
                                                                ) : (
                                                                    <p>No available sizes</p> // Message when no sizes are available
                                                                )}
                                                        </div>
                                                    )}
                                                     
                                                </div>
                                                
                                                <div className="product-info">
                                                    <img src={`https://cdn8.apopixx.de/500/web_schraeg_png/${product.PZN2}.png`} alt="" />
                                                    <div className="content-product">
                                                        <h1 className="title-product">{product.EIGENE_BEZEICHNUNG}</h1>
                                                        <p className="product-grams">{product.PKG}</p>
                                                        <div className="product-quantity">
                                                            <input onChange={(e) => handleQuantityChange(e, product.PZN2)} type="number" value={quantities[product.PZN2] || 1} />
                                                            <p> x {(product.price * (quantities[product.PZN2] || 1)).toFixed(2)}€ </p>
                                                        </div>
                                                        <div className="product-shop-btn">
                                                            <a 
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={(e) => addBasket(product, quantities[product.PZN2] || 1)}
                                                                // href={`https://www.plus1apotheke.de/checkout/cart/baskethandover/${product.PZN2}-${quantities[product.PZN2] || 1}`}
                                                            >
                                                                <button>
                                                                    <img src={shoping_cart} alt="" /> in den Warenkorb
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )})}
                                        <h3 className='tips-heading-h1'>Was Sie sonst noch tun können (Adjuvanzien)</h3>
                                        <p dangerouslySetInnerHTML={{ __html: selectedIndecation?.tips }}></p>
                                    </div>
                                </div> : ""}
                                
                                {submit ? <>
                                    <button className='next-btn'>
                                        <a target='_blank' href="https://www.plus1apotheke.de/">Zum Shop</a>
                                    </button>
                                    {screen > 1 ? <button onClick={getBack} className='back-btn-2'>{"< Zuruck"}</button> : ''}
                                </> : <>
                                    {no_next ? <button onClick={WARNFUN} className='next-btn'>Weiter</button> : <button onClick={screenChanger} className='next-btn'>Weiter</button> }
                                    {screen > 1 ? <button onClick={getBack} className='back-btn'>{"< Zuruck"}</button> : ''}
                                </> }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className='footer-white'>
                <div className="footer-links-white">
                    <a href="">Impressum</a>
                    <a href="">Datenschutz</a>
                    <a href="">Kontakdaten</a>
                </div>
            </footer>
        </div>
    );
}

export default Dashboard;
