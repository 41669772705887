// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-holder {
    display: inline-block;
    width: 100%;
    height: 100vh;
    background-color: white;
}


.search-results {
    width: 80%;
    margin: 20px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/search/search.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;;AAGA;IACI,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".search-holder {\n    display: inline-block;\n    width: 100%;\n    height: 100vh;\n    background-color: white;\n}\n\n\n.search-results {\n    width: 80%;\n    margin: 20px auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
