import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {url} from '../../config/backed_url.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { jwtDecode }  from 'jwt-decode'; 



const GoogleAuth = ({setLogin}) => {

    const navigate = useNavigate()


    const fetchUserData = async (token) => {
      try {
        const response = await axios.get(`${url}/api/v1/me/`, {
          headers: {
            Authorization: `Token ${token}`, 
          },
        });
        localStorage.setItem('user', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
  };


    const handleSuccess = async (response) => {
        try {
          const token = response.credential;

          const user_data = jwtDecode(token);


          const acccount_details = {
            first_name: user_data.name,
            email: user_data.email,
            username: user_data.name,
            password: user_data.email+user_data.name,
            picture: user_data.picture
          }

          setLogin(true)
          // console.log('Token:', token); // Debug token
          // const res = await axios.post(`${url}/api/v1/google/`, { access_token: token });
      
          // const data = res.data;
          // console.log(data);

          // axios.post(`${url}/api/v1/register/`, acccount_details).then((res) => {

          localStorage.setItem('token', 'djdscndsklnckjdscnkdjs')
          localStorage.setItem('user', JSON.stringify(acccount_details));
          navigate('/'); 

          //   setLogin(true)
          //   navigate('/'); 
          //   console.log(res.data.key);
          //   localStorage.setItem('token', res.data.key)

          //   toast.success("You are Logged In Via Google!")
          //   setLogin(true)
          //   navigate('/')
            
          // }).catch((err) => {
          //     console.log(err.response);

          //     if(err.response.data.error){
          //         toast.warning(err.response.data.error)
          //     }

          // })

        } catch (error) {
          console.error('Error during authentication:', error);
        }


    }
      

        const handleFailure = (response) => {
            console.log('Login Failed:', response);
        };

  return (
    <GoogleOAuthProvider clientId="657891357349-vui71k56nqrvet00hb2md6rpi5jorcf5.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        useOneTap
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
