import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { url } from '../../config/backed_url';
import { useState, useEffect } from 'react';


function Logout({setLogin, setL, l}) {
    const navigate = useNavigate();

    // const [l, setL] = useState(false)

    useEffect(() => {
  
  
      const token = localStorage.getItem('token')

      if(token){
        setL(true)
      } else {
        setL(false)
      }

    }, [])
   
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user')
        setLogin(false)
        setL(false)
        navigate('/');
     
    }

    const handleLogin = () => {
        navigate('/login');
    }

    return (
        <div className='logout-btn'>
            {l ? <button onClick={handleLogout}>Logout</button> : <button onClick={handleLogin}>Log In / Sign In</button>}
        </div>
    );
}

export default Logout;
