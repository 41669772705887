import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/navbar';
import './search.css';
import { useLocation } from 'react-router-dom';
import { url } from '../../config/backed_url';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Search({setLogin}) {
  const location = useLocation();
  const navigate = useNavigate();

  const [indecations, setIndecations] = useState([]);
  const [searchResults, setSearchResults] = useState(null);

  const handleRedirect = (indication) => {
    navigate('/dashboard', {
      state: { selectedIndecation: indication, indecations },
    });
  };

  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    
    const token = localStorage.getItem('token');
    const cachedIndecations = localStorage.getItem('indecations');
    
    if (cachedIndecations) {
      // Use cached data
      const parsedData = JSON.parse(cachedIndecations);
      setIndecations(parsedData);
      const searchResults = parsedData.filter(i => i.INDIKATION === query);
      setSearchResults(searchResults.length ? searchResults : null);
    } else {
      // Fetch from API if no cache available
      axios.get(`${url}/api/v1/general/`, {
        headers: { 'Authorization': `Token ${token}` }
      })
      .then((res) => {
        const data = res.data;
        setIndecations(data);
        localStorage.setItem('indecations', JSON.stringify(data));
        const searchResults = data.filter(i => i.INDIKATION === query);
        setSearchResults(searchResults.length ? searchResults : null);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [query]);

  return (
    <div className='search-container'>
      <Navbar setLogin={setLogin} />
      <div className="search-holder">
        <div className="search-results">
          {searchResults ? (
            searchResults.map((indecation, index) => (
              <div className='indication' key={index}>
                <img src={indecation.IMAGE} alt="" />
                <p onClick={() => handleRedirect(indecation)}>{indecation.INDIKATION}</p>
              </div>
            ))
          ) : (
            <span>No Search Result Found!</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Search;
