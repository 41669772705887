import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './components/login/login';
import Register from './components/register/register';
import Home from './components/home/home';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './components/dashboard/dashboard';
import Search from './components/search/search';
import React from 'react';
import axios from 'axios';
import { url } from './config/backed_url';

function App() {

  const [login, setLogin] = useState(false)

  const [password, setPassword] = useState('');
  const [lock, setLock] = useState(true);


  const handleLock = (e) => {
    if (password === 'STRONG$$$$$$!^&*%^%!W#') {
      setLock(true)
    }
  };


  // Function to fetch logged-in user's data
  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(`${url}/api/v1/me/`, {
        headers: {
          Authorization: `Token ${token}`,  // Send the token in the Authorization header
        },
      });
      localStorage.setItem('user', JSON.stringify(response.data));
      
      setLogin(true);  
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLogin(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token')
    
    if(token){
      setLogin(true)
      if (localStorage.getItem('user')) {
        
      } else {
        fetchUserData(token)
      }
    } else {
      setLogin(false)
    }
  }, [login])
  

  if (lock) {
    return (
      <div className="">
        <ToastContainer />
        <Routes>
            
            
            {login ? 
              <>
                <Route path='/' element={<Home setLogin={setLogin} login={login} />} />  
                <Route path='/dashboard' element={<Dashboard setLogin={setLogin} />} />
                <Route path='/search' element={<Search setLogin={setLogin} />} />  
              </> 
                :
              <>
                <Route path='/' element={<Home setLogin={setLogin} login={login} />}  />  
                <Route path='/login' element={<Login setLogin={setLogin} />} />
                <Route path='/register' element={<Register setLogin={setLogin}/>} />
              </>
            }
            
         </Routes>
      </div>
  );
  } else {
    return (
      <div className="">
        <h1>Admin Controls</h1>
        <input style={{
          fontSize: '16px',  // Ensure font size is at least 16px
          padding: '10px',   // Comfortable padding for touch
          width: '100%',     // Optional: make the input full-width
          boxSizing: 'border-box',  // Prevents the input from overflowing
        }} type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
        <button onClick={handleLock}>Enter Into Website</button>
      </div>
  );

  }

  
}

export default App;
