import React, {useState, useEffect} from 'react'
import './register.css'
import whiteIMG from '../../images/logo-white.png'
import GoogleImage from '../../images/google.png'
import { Link, redirect, useNavigate } from 'react-router-dom'
import {url} from '../../config/backed_url.js'
import { toast } from 'react-toastify';
import axios from 'axios'
import GoogleAuth from '../google/google.jsx'


function Register({setLogin}) {
    const navigate = useNavigate(); // useHistory for React Router v5

    const [error, setError] = useState(false)
    const [full_name, setFullName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleFullName = (e) => {
        setFullName(e.target.value)
    }

    const handleUsername = (e) => {
        setUsername(e.target.value)
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const fetchUserData = async (token) => {
        try {
          const response = await axios.get(`${url}/api/v1/me/`, {
            headers: {
              Authorization: `Token ${token}`, 
            },
          });
          localStorage.setItem('user', JSON.stringify(response.data));
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
    };


    const register_api = () => {

        const data = {
            first_name: full_name,
            username: username,
            email: email,
            password: password,
        }

        if(email == '' || password == '' || username == '' || full_name == '') {
            toast.warning("Fill All Details");

            return ''
        }


        axios.post(`${url}/api/v1/register/`, data).then((res) => {

            localStorage.setItem('token', res.data.token)
            fetchUserData(res.data.key)

            setError(false)
            setLogin(true)
            navigate('/'); 
            toast.success("User Registered!")
            
        }).catch((err) => {
            console.log(err.response);

            if(err.response.data.error){
                toast.warning(err.response.data.error)
            }

            setError(true)
        })
    }



  return (
    <div className='login'>
        <div className="header-logo">
            <img src={whiteIMG} alt="" srcset="" />
        </div>

        <div className="login-form-container">
            <div className="image-holder">
                <div className="bg-extra">

                </div>
            </div>
            <div className="form-container ">
                <div className="form-inner-container ">
                    <div className="o-auth">
                        <p>Sign up to <span>+1 Medi</span></p>
                        {/* <div className="google-login-btn">
                            <img src={GoogleImage} className='google-img' alt="" srcset="" />
                            <div className="google-content"> */}
                                <GoogleAuth setLogin={setLogin} />
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                    <div class="hr-sect">OR</div>
                    <div className="the-form-login">
                        <div className="form-field-holder">
                            <div className="input-container">
                                <label htmlFor="full-name">Full Name</label>
                                <br />
                                <input value={full_name} onChange={(e) => handleFullName(e)} type="text" />
                            </div>
                            <div className="input-container">
                                <label  htmlFor="username">Username</label>
                                <br />
                                <input value={username} onChange={(e) => handleUsername(e)} type="text" />
                            </div>
                        </div>
                        <div className="input-container">
                            <label htmlFor="email">Email Address</label>
                            <input value={email} onChange={(e) => handleEmail(e)} type="text" />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Password</label>
                            <input value={password} onChange={(e) => handlePassword(e)} type="password" placeholder='6+ characters'/>
                        </div>
                        <div className="flex privacy-accept-btn">
                            <input type="checkbox"/>
                            <span>Privacy & Policy</span>
                        </div>
                        <div className="submit-btn-content">
                            <button onClick={register_api} className='form-submit-button-auth'>Create Account</button>
                            <p >Already Registered? <Link className='blue-link' to='/login'>Log In</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register