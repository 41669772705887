import React, { useState, useEffect } from 'react'
import './login.css'
import whiteIMG from '../../images/logo-white.svg'
import GoogleImage from '../../images/google.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import {url} from '../../config/backed_url.js'
import { toast } from 'react-toastify';
import GoogleAuth from '../google/google.jsx'


function Login({setLogin}) {

    const [error, setError] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const fetchUserData = async (token) => {
        try {
          const response = await axios.get(`${url}/api/v1/me/`, {
            headers: {
              Authorization: `Token ${token}`, 
            },
          });
          localStorage.setItem('user', JSON.stringify(response.data));
          navigate('/')
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
    };


    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const login_api = () => {

        const data = {
            email: email,
            password: password
        }

        if(email == '' || password == '') {
            toast.warning("Fill All Details");

            return ''
        }

        axios.post(`${url}/api/auth/login/`, data).then((res) => {

            console.log(res.data.key);
            localStorage.setItem('token', res.data.key)
            setError(false)
            toast.success("You Are Logged In Now!")
            setLogin(true)
            fetchUserData(res.data.key)

           

        }).catch((err) => {
            console.log(err.response.data);
            setError(true)
        })
    }
    
  return (
    <div className='login '>

        <div className="header-logo">
            <img src={whiteIMG} alt="" srcset="" />
        </div>

        <div className="login-form-container the-height">
            <div className="image-holder">
                <div className="bg-extra">

                </div>
            </div>
            <div className="form-container">
                <div className="form-inner-container topper">
                    <div className="o-auth">
                        <p>Log In to <span>+1 Medi</span></p>
                        <GoogleAuth setLogin={setLogin} />
                    </div>
                    <div class="hr-sect">OR</div>
                    <div className="the-form-login">
                        <div className="input-container">
                            <label htmlFor="email">Email Address</label>
                            <input type="text" value={email} onChange={(e) => handleEmail(e)} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Password</label>
                            <input type="password" placeholder='6+ characters' value={password} onChange={(e) => handlePassword(e)} />
                        </div>
                        <div className="flex privacy-accept-btn">
                            <input type="checkbox"/>
                            <span>Remember</span>
                        </div>
                        <div className="submit-btn-content">
                            <button onClick={login_api} className='form-submit-button-auth'>Login</button>
                            <p >Don’t you have an account? <Link className='blue-link' to='/register'>Register Now</Link></p>
                            <span className='red-error'>{error ? "Login Failed, Incorrect Email or Password!" : ""}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login