import React, { useEffect, useState } from 'react';
import './navbar.css';
import WhiteLogo from '../../images/logo-white.svg';
import searchIMG from '../../images/search.png';
import Logout from '../navbar/logout.jsx';
import { useNavigate } from 'react-router-dom';
import Basket from '../../images/basket.svg'
import ProfileIMG from '../../images/person.svg'
import MenuIcon from '../../images/menu-icon.svg'; // Add a hamburger icon
import CloseIcon from '../../images/close-icon.svg'; // Add a close icon
import { toast } from 'react-toastify';



function Navbar({ setLogin, login }) {

  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [user, setUser] = useState({})

  const [isBasketOpen, setIsBasketOpen] = useState(false); // State to track basket visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu toggle

  const [products, setProducts] = useState([])

 
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  const homelink = () => {
    navigate('/');
  };

  const [l, setL] = useState(false)

  useEffect(() => {

    const storedUser = localStorage.getItem('user');

    console.log(storedUser);
    
    
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        if (parsedUser && parsedUser.username) {
          setUser(parsedUser);
        }
      } catch (error) {
        console.error('Failed to parse user from localStorage:', error);
        setUser({});
      }
    }

    const token = localStorage.getItem('token')

    if(token){
      setL(true)
    } else {
      setL(false)
    }

  }, [])
  

  const basketHandler = () => {


    const pro = JSON.parse(localStorage.getItem('basket')) || [];

    if (pro) {
      setProducts(pro)
    }      

    setIsBasketOpen(!isBasketOpen)


  }

  const basketCloser = () => {
    localStorage.removeItem('basket')

   


    setIsBasketOpen(!isBasketOpen)
  }

  const addToCart = (product) => {
  const quantity = product.quantity || 1;
  const url = `https://www.plus1apotheke.de/checkout/cart/baskethandover/${product.PZN2}-${quantity}`;

  
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });
  
};

const cartout = async () => {
  const basket = JSON.parse(localStorage.getItem('basket')) || [];

  if (basket.length === 0) {
    toast.warning("Add Products to Cart First!");
  } else {
    // Loop through each product and add them to the cart
    for (const product of basket) {
      try {
        await addToCart(product); // Wait for each product to be added before continuing
      } catch (error) {
        console.error(`Error adding product ${product.PZN2} to cart`, error);
      }
    }

    // After all products are added, navigate to the checkout page
    window.location.href = 'https://www.plus1apotheke.de/checkout/cart';
  }
};

  return (
    <header className='navbar'>
      <div className="logo" onClick={homelink}>
        <img src={WhiteLogo} alt="Company Logo" />
      </div>

     
      
      <div className="search-bar">
        <div className="iconbox">
          <img src={searchIMG} alt="Search Icon" />
        </div>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyPress={handleSearchKeyPress}
        />
      </div>

      <div className="mobile-items">

       

        <div className="basket-holder-mobile" >
          <img src={Basket} alt="Basket" onClick={basketHandler} />
          {isBasketOpen && (
            <div className="basket-popup">
              <h4>Basket</h4>
              {products.length > 0 ? (
                <ul>
                  {products.map((product) => (
                    <li key={product.id}>
                      {product.EIGENE_BEZEICHNUNG} - €{product.price} x {product.quantity} = €{product.quantity * product.price}
                    </li>
                  ))}
                  <button onClick={cartout}>Buy Now</button>
                  <button onClick={basketCloser}>Clear All</button>
                </ul>

              ) : (
                <p>Your cart is empty.</p>
              )}
            </div>
          )}
        </div>

        <div className="mobile-menu-icon" onClick={() => {
           setIsMenuOpen(!isMenuOpen)
           if (isMenuOpen) {
            if (document.getElementById('s-b')) {
              document.getElementById('s-b').style.zIndex = 1;
            }
           } else {
            if (document.getElementById('s-b')) {
              document.getElementById('s-b').style.zIndex = -1;
            }
           }
        }}>
          <img src={isMenuOpen ? CloseIcon : MenuIcon} alt="Menu Icon" />
        </div>
      </div>

     
      
      <div className="nav-info">
        
      {l ? <div className="profile-holder">
          {user?.picture ? <div className="profile-img">
             <img src={user?.picture} alt="Profile" /> 
            </div> : ''}
          {/* <div className="profile-img"> */}
            {/* <img src={ProfileIMG} alt="" srcset="" /> */}
          {/* </div> */}
          <div className="profile-content">
            <p>Welcome</p>
            <h4>{user?.username ? user.username : ''}</h4>
          </div>
        </div> : null}

        <div className="basket-holder" >
        <img src={Basket} alt="Basket" onClick={basketHandler} />
        {isBasketOpen && (
            <div className="basket-popup">
              <h4>Basket</h4>
              {products.length > 0 ? (
                <ul>
                  {products.map((product) => (
                    <li key={product.id}>
                      {product.EIGENE_BEZEICHNUNG} - €{product.price} x {product.quantity} = €{product.quantity * product.price}
                    </li>
                  ))}
                  <button onClick={cartout}>Buy Now</button>
                  <button onClick={basketCloser}>Clear All</button>
                </ul>

              ) : (
                <p>Your cart is empty.</p>
              )}
            </div>
          )}
      </div>

        

        
      <Logout setL={setL} l={l} setLogin={setLogin} />

        
      
      </div>

      

      {/* Menu items for mobile dropdown */}
      {isMenuOpen && (
        <div className="mobile-dropdown">
          <div className="search-bar-mobile">
            <div className="iconbox">
              <img src={searchIMG} alt="Search Icon" />
            </div>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleSearchKeyPress}
            />
          </div>

          {l ?  <div className="profile-holder-mobile">
            
            {user?.picture ? <div className="profile-img">
             <img src={user?.picture} alt="Profile" /> 
            </div> : ''}
            
            <div className="profile-content">
              <p>Welcome</p>
              <h4>{user?.username ? user.username : ''}</h4>
            </div>
          </div> : null}
         

          <Logout setL={setL} l={l} setLogin={setLogin} />
        </div>
      )}
    </header>
  );
}

export default Navbar;
