import React from 'react'
import './footer.css'

function Footer() {
  return (
    <div className='footer'>
        <div className="footer-form">
            <h2>Contact Us</h2>
            <div className="footer-form-fields">
                <div className="input-handler">
                    <label htmlFor="">Full Name</label>
                    <br />
                    <input type="text" placeholder='Full Name' />
                </div>
                <div className="input-handler">
                    <label htmlFor="">Email</label>
                    <br />
                    <input type="text" placeholder='Email' />
                </div>
                <div className="input-handler">
                    <label htmlFor="">Phone</label>
                    <br />
                    <input type="text" placeholder='Phone' />
                </div>
                <div className="input-handler">
                    <label htmlFor="">Message</label>
                    <br />
                    <textarea name="" id="" placeholder='Message' ></textarea>
                </div>
                <button className='form-submit-btn-footer'>Invia</button>
            </div>
        </div>

        <div className="footer-nav">
            <div className="side-reserve">
                <p>
                    All rights 2024
                </p>
            </div>
            <div className="footer-links">
                <a href="">Impressum</a>
                <a href="">Datenschutz</a>
                <a href="">Kontakdaten</a>
            </div>
        </div>
    </div>
  )
}

export default Footer